<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.error">
          <v-card 
            style="border-radius: 30px;">
            <v-card-text 
              class="pt-2 pb-6">
              <v-img 
                width="150"
                height="150"
                alt="amanata" 
                :src="require('@/assets/icon/info_warning.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div 
                class="body-1 text-center line-text-second">
                {{ message.error }}
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.type_cuti">
          <v-card 
            style="border-radius: 30px;">
            <v-card-text 
              class="py-6">
              <v-row 
                align="center" 
                justify="center">
                <v-col 
                  cols="4" 
                  class="text-center">
                  <div
                    @click="dialog.type_cuti = false; sheet.add_holiday = true; reset_first('ijin');"
                    class="cursor-pointer">
                    <v-avatar 
                      tile
                      size="50" 
                      class="cursor-pointer" 
                      v-ripple
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                      <v-img 
                        alt="amanata" 
                        :src="require('@/assets/icon/ijin.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <div 
                      class="font-weight-bold black--text mt-2">
                      Izin
                    </div>
                  </div>
                </v-col>

                <v-col 
                  cols="4" 
                  class="text-center px-0">
                  <div 
                    class="d-flex align-center">
                    <v-divider 
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />

                    <div 
                      class="mx-2 font-weight-bold"
                      style="opacity: 80%;">
                      Atau
                    </div>

                    <v-divider 
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />
                  </div>
                </v-col>

                <v-col 
                  cols="4" 
                  class="text-center">
                  <div
                    @click="dialog.type_cuti = false; sheet.add_holiday = true; reset_first('cuti');"
                    class="cursor-pointer">
                    <v-avatar 
                      tile
                      size="50" 
                      class="cursor-pointer" 
                      v-ripple
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                      <v-img 
                        alt="amanata" 
                        :src="require('@/assets/icon/cuti.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <div 
                      class="font-weight-bold black--text mt-2">
                      Cuti
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text>
              <v-btn
                @click="dialog.type_cuti = true"

                rounded
                block
                outlined
                large
                color="#e0e0e0"

                class="body-1 font-weight-bold text-capitalize d-flex align-center">
                <v-avatar
                  size="18"
                  tile>
                  <v-img 
                    contain
                    alt="amanata" 
                    :src="require('@/assets/icon/add_holiday.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="15"
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
                
                <span
                  class="ml-2"
                  :style="`color: ${set_color};`">
                  Ajukan Izin & Cuti
                </span>
              </v-btn>
              
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"

                solo
                class="no_box mt-6 mb-5"
                :color="set_color"
                single-line
                hide-details

                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-row>
                <v-col
                  v-for="(item, index) in menu" 
                  :key="index"
                  cols="6"
                  class="pt-2 mb-1">
                  <div>
                    <v-btn
                      small
                      rounded
                      block
                      elevation="0"
                      @click="to_slide(index)"
                      class="body-2 text-capitalize"
                      :color="selected.menu === index ? set_color : '#eeeeee'"
                      :class="selected.menu === index ? 'white--text font-weight-bold' : 'grey--text'">
                      {{ item.text }}
                    </v-btn>
      
                    <div v-if="selected.menu === index"
                      class="text-center">
                      <v-icon
                        :color="set_color"
                        size="10">
                        mdi-circle
                      </v-icon>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-list 
                flat 
                three-line 
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 3 : list"
                    :key="index"
                    :loading="process.load"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.length > 0"
                          @click="selected.holiday = item; sheet.detail_holiday = true;"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 20px;
                                background: #FFFFFF40;
                                border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;"
                          :style="`border-left: 4px solid ${set_color};`">
                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="caption mb-1 d-flex align-center">
                              <v-icon x-small>mdi-calendar-month-outline</v-icon>
                              
                              <span
                                class="ml-1">
                                {{ item.pengajuan_date | date }}
                              </span>

                              <v-icon
                                size="20"
                                style="position: absolute; top: 10px; right: 10px;"
                                :color="item.status === 'submission' ? 'blue' :
                                        item.status === 'approved' ? 'green' : 'red'">
                                {{
                                  item.status === 'submission' ? 'mdi-shape-circle-plus' :
                                  item.status === 'approved' ? 'mdi-checkbox-marked-circle-outline' : 'mdi-cancel'
                                }}
                              </v-icon>
                            </v-list-item-subtitle>

                            <v-list-item-title
                              class="body-1 font-weight-bold mb-2">
                              {{ item.user_name }}
                            </v-list-item-title>

                            <v-list-item-subtitle 
                              class="body-2">
                              <span
                                class="line-text-first one-line">
                                {{ item.note }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle 
                              class="body-2 line-text-first">
                              <v-divider
                                class="my-2"
                                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />
                                
                              <div
                                class="d-flex align-center">
                                Tanggal Mulai: 

                                <v-spacer />

                                {{ item.start_date | date }}
                              </div>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle v-if="item.start_time === ''"
                              class="body-2 line-text-first d-flex align-center">
                              Tanggal Selesai: 

                              <v-spacer />
                              
                              {{ item.end_date | date }}
                            </v-list-item-subtitle>

                            <v-list-item-subtitle v-if="item.start_time !== ''"
                              class="body-2 line-text-first">
                              <v-divider
                                class="my-2"
                                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />
                                
                              <div
                                class="d-flex align-center">
                                Jam Mulai: 

                                <v-spacer />

                                {{ item.start_time }}
                              </div>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle v-if="item.end_time !== ''"
                              class="body-2 line-text-first d-flex align-center">
                              Jam Selesai: 

                              <v-spacer />
                              
                              {{ item.end_time }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <v-card-text v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center pb-0">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"

                  rounded
                  small
                  outlined
                  :color="set_color"

                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  lebih banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Perizinan & Cuti" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.add_holiday"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title 
              @click="sheet.add_holiday = false; reset(); dialog.type_cuti = true;"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon 
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Ajukan Izin & Cuti
              </span>
            </v-card-title>

            <v-card-text 
              class="py-16 mt-3 mb-16 px-4">
              <div class="mb-16">
                <ValidationObserver 
                  ref="form">
                  <v-form>
                    <ValidationProvider 
                      name="value" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Jumlah <span class="text-capitalize">{{ form.type === 'ijin' ? 'izin' : form.type }}</span>
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box"
                        :color="set_color"

                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="0"

                        placeholder="Contoh. 2"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        suffix="Hari"

                        v-model="form.value"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <div>
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Tanggal Pengajuan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-menu
                        ref="submissiondate"
                        min-width="290px"
                        max-width="290px"
                        transition="scale-transition"
                        offset-y
                        :nudge-right="40"
                        :close-on-content-click="false"
                        v-model="picker.submission">
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider 
                            name="pengajuan_date" 
                            rules="required"
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save()"

                              readonly
                              solo
                              class="no_box"
                              :color="set_color"
                              placeholder="Contoh. 2022-12-30"
                              
                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                              clearable
                              @click:clear="form.pengajuan_date = ''; form.start_date = ''; form.end_date = '';"
                              v-on="on"
                              v-bind="attrs"

                              v-model="form.pengajuan_date"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </template>

                        <v-date-picker 
                          :color="set_color"
                          v-model="form.pengajuan_date"
                          @change="form.start_date = ''; form.end_date = '';"
                          @input="picker.submission = false">
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <v-checkbox v-if="form.type === 'ijin'"
                      v-model="form.check_time"
                      label="Izin meninggalkan jam kerja?"
                      :color="set_color"
                      class="mt-0 mb-6"
                      @change="form.end_date = '';
                              form.start_time = '';
                              form.end_time = '';"
                      hide-details>
                    </v-checkbox>

                    <div>
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Tanggal Mulai <span class="text-capitalize">{{ form.type === 'ijin' ? 'izin' : form.type }}</span>
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-menu
                        ref="startdate"
                        min-width="290px"
                        max-width="290px"
                        transition="scale-transition"
                        offset-y
                        :nudge-right="40"
                        :close-on-content-click="false"
                        v-model="picker.start">
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider 
                            name="start_date" 
                            rules="required"
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save()"

                              readonly
                              solo
                              class="no_box"
                              :color="set_color"
                              placeholder="Contoh. 2023-01-01"
                              
                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                              clearable
                              @click:clear="form.start_date = ''; form.end_date = '';"
                              v-on="on"
                              v-bind="attrs"

                              v-model="form.start_date"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </template>

                        <v-date-picker 
                          :color="set_color"
                          :min="form.pengajuan_date"
                          v-model="form.start_date"
                          @change="form.end_date = ''"
                          @input="picker.start = false">
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <div v-if="!form.check_time">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Tanggal Selesai <span class="text-capitalize">{{ form.type === 'ijin' ? 'izin' : form.type }}</span>
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-menu
                        ref="enddate"
                        min-width="290px"
                        max-width="290px"
                        transition="scale-transition"
                        offset-y
                        :nudge-right="40"
                        :close-on-content-click="false"
                        v-model="picker.end">
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider 
                            name="end_date" 
                            rules="required"
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save()"

                              disabled
                              readonly
                              solo
                              class="no_box"
                              :color="set_color"
                              placeholder="Contoh. 2023-01-02"
                              
                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                              clearable
                              @click:clear="form.end_date = ''"
                              v-on="on"
                              v-bind="attrs"

                              v-model="form.end_date"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </template>

                        <v-date-picker 
                          :color="set_color"
                          :min="form.start_date"
                          v-model="form.end_date"
                          @input="picker.end = false">
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <div v-if="form.check_time">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Jam Mulai
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-menu
                        ref="start_time"
                        v-model="time.start"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="form.start_time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider 
                            name="Jam Mulai" 
                            rules="required"
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save()"

                              readonly
                              solo
                              class="no_box"
                              :color="set_color"
                              placeholder="Contoh. 09:00"

                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                              clearable
                              @click:clear="form.start_time = ''; form.end_time = '';"
                              v-on="on"
                              v-bind="attrs"

                              v-model="form.start_time"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </template>

                        <v-time-picker
                          v-if="time.start"
                          v-model="form.start_time"
                          full-width
                          :color="set_color"
                          format="24hr"
                          @click:minute="$refs.start_time.save(`${form.start_time}`); form.end_time = '';">
                        </v-time-picker>
                      </v-menu>
                    </div>
                    
                    <div v-if="form.check_time">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Jam Selesai
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-menu
                        ref="end_time"
                        v-model="time.end"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="form.end_time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider 
                            name="Jam Selesai" 
                            rules="required"
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save()"

                              readonly
                              solo
                              class="no_box"
                              :color="set_color"
                              placeholder="Contoh. 17:00"

                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                              clearable
                              @click:clear="form.end_time = ''"
                              v-on="on"
                              v-bind="attrs"

                              v-model="form.end_time"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </template>

                        <v-time-picker
                          v-if="time.end"
                          :min="form.start_time"
                          v-model="form.end_time"
                          full-width
                          :color="set_color"
                          format="24hr"
                          @click:minute="$refs.end_time.save(`${form.end_time}`)">
                        </v-time-picker>
                      </v-menu>
                    </div>

                    <div
                      class="mb-6">
                      <div class="body-2">
                        Lampiran
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>

                      <v-row>
                        <v-col
                          cols="6">
                          <uploadfolder 
                            v-model="upload.folder">
                            <div slot="activator">
                              <v-btn
                                :disabled="process.file || process.doc"
                                :loading="process.file"

                                rounded
                                large
                                block
                                :color="`${set_color_rgb_soft}`"
                                elevation="0"

                                class="body-2 font-weight-bold text-capitalize d-flex align-center"
                                :style="`color: ${set_color};`">
                                <div>
                                  <v-img
                                    contain
                                    width="20"
                                    height="20"
                                    alt="amanata"
                                    :src="require('@/assets/icon/upload_image.png')">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          size="20"
                                          color="white">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </div>

                                <span
                                  class="ml-2">
                                  Upload Gambar
                                </span>
                              </v-btn>
                            </div>
                          </uploadfolder>
                        </v-col>

                        <v-col
                          cols="6">
                          <uploaddocument 
                            v-model="upload.doc">
                            <div slot="activator">
                              <v-btn
                                :disabled="process.doc || process.file"
                                :loading="process.doc"

                                rounded
                                large
                                block
                                :color="`${set_color_rgb_soft}`"
                                elevation="0"

                                class="body-2 font-weight-bold text-capitalize d-flex align-center"
                                :style="`color: ${set_color};`">
                                <div>
                                  <v-img
                                    contain
                                    width="20"
                                    height="20"
                                    alt="amanata"
                                    :src="require('@/assets/icon/upload_folder.png')">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          size="20"
                                          color="white">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </div>

                                <span
                                  class="ml-2">
                                  Upload PDF
                                </span>
                              </v-btn>
                            </div>
                          </uploaddocument>
                        </v-col>
                      </v-row>

                      <div v-if="form.lampiran === ''"
                        class="line-text-second font-italic"
                        style="opacity: 70%;">
                        Upload Gambar atau PDF untuk melengkapi data lampiran dari ajuan {{ form.type === 'ijin' ? 'izin' : form.type }} Anda.
                      </div>

                      <div v-if="form.lampiran !== ''"
                        class="mt-2">
                        <div v-if="form.lampiran.split('.').pop() !== 'pdf'">
                          <v-img
                            contain
                            alt="amanata" 
                            width="100%"
                            height="auto"
                            :src="form.lampiran"
                            class="ma-auto border-radius">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>

                        <div v-else>
                          <iframe 
                            :src="`${form.lampiran}#toolbar=0`" 
                            allowfullscreen
                            frameborder="0" 
                            width="100%"
                            height="397"
                            style="border-radius: 15px; border: 2px solid #323639;">
                          </iframe>
                        </div>
                      </div>
                    </div>

                    <ValidationProvider 
                      name="note" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Keterangan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-textarea
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box_area"
                        :color="set_color"
                        rows="5"
                        placeholder="Contoh. Tuliskan keterangan disini . . ."
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.note"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-textarea>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="pb-0">
                    <v-img 
                      contain
                      alt="amanata" 
                      :src="require('@/assets/icon/schedule.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text pb-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Silahkan mengisi form data diatas untuk mengajukan {{ form.type === 'ijin' ? 'izin' : form.type }} Anda, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn
                      v-on:keyup.enter="save()"
                      @click="save()"
                      :disabled="process.form"
                      :loading="process.form"

                      rounded
                      block
                      large
                      color="#348d37"

                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn 
                      @click="sheet.add_holiday = false; reset(); dialog.type_cuti = true;"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      color="red"
                      outlined
                      
                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_holiday"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title 
              @click="sheet.detail_holiday = false"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon 
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Detail Ajuan Izin & Cuti
              </span>
            </v-card-title>
            
            <v-card-text v-if="Object.keys(selected.holiday).length > 0"
              class="pt-16 mt-3 px-4">
              <div
                class="caption text-capitalize mb-1 d-flex align-center">
                <v-icon x-small>mdi-calendar-month-outline</v-icon>
                
                <span
                  class="ml-1">
                  {{ selected.holiday.status }}
                </span>
              </div>

              <div
                class="title font-weight-bold black--text">
                {{ selected.holiday.pengajuan_date | date }}
              </div>

              <v-divider
                class="mt-2 mb-6"
                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

              <v-row
                class="mb-6">
                <v-col
                  cols="6"
                  class="py-0">
                  <div
                    class="caption">
                    Tanggal Mulai:
                  </div>

                  <div
                    class="green--text">
                    {{ selected.holiday.start_date | datetime }}
                  </div>
                </v-col>

                <v-col v-if="selected.holiday.start_time === ''"
                  cols="6"
                  class="py-0">
                  <div
                    class="caption">
                    Tanggal Selesai:
                  </div>

                  <div
                    class="red--text">
                    {{ selected.holiday.end_date | datetime }}
                  </div>
                </v-col>
              </v-row>

              <v-row v-if="selected.holiday.start_time !== ''"
                class="mb-6">
                <v-col
                  cols="6"
                  class="py-0">
                  <div
                    class="caption">
                    Jam Mulai:
                  </div>

                  <div
                    class="green--text">
                    {{ selected.holiday.start_time }}
                  </div>
                </v-col>

                <v-col
                  cols="6"
                  class="py-0">
                  <div
                    class="caption">
                    Jam Selesai:
                  </div>

                  <div
                    class="red--text">
                    {{ selected.holiday.end_time }}
                  </div>
                </v-col>
              </v-row>

              <div
                class="body-2 font-weight-bold black--text mb-2">
                Keterangan:
              </div>

              <div
                class="body-2 line-text-second mb-6">
                {{ selected.holiday.note !== '' ? selected.holiday.note : '-' }}
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-4">
                Lampiran:
              </div>

              <div v-if="selected.holiday.lampiran !== ''"
                class="pb-16 mb-6">
                <div v-if="selected.holiday.lampiran.split('.').pop() !== 'pdf'">
                  <v-img
                    contain
                    alt="amanata" 
                    width="100%"
                    height="auto"
                    :src="selected.holiday.lampiran"
                    class="ma-auto border-radius">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>

                <div v-else>
                  <iframe 
                    :src="`${selected.holiday.lampiran}#toolbar=0`" 
                    allowfullscreen
                    frameborder="0" 
                    width="100%"
                    height="397"
                    style="border-radius: 15px; border: 2px solid #323639;">
                  </iframe>
                </div>
              </div>

              <div v-else
                class="pa-4"
                style="border: 2px dashed #eaeaea; border-radius: 10px;">
                Data lampiran tidak tersedia!!!
              </div>

              <!-- <div v-else
                class="text-center"
                style="margin-top: 225px;">
                <v-img 
                  class="mx-auto"
                  contain
                  alt="amanata"
                  width="150"
                  height="150"
                  :src="require('@/assets/image/empty.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div class="body-1 font-weight-light text-second my-6">
                  Data lampiran tidak tersedia!!!
                </div>
              </div> -->
            </v-card-text>

            <v-card v-if="selected.holiday.status === 'submission'"
              flat
              color="#FFFFFF"
              min-height="50"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row>
                  <v-col 
                    cols="12" 
                    class="pb-0">
                    <v-btn
                      @click="sheet.add_holiday = true; reset(selected.holiday);"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      color="blue"
                      
                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-circle-edit-outline</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Ubah
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from "moment"
  import "moment/locale/id"
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          error: false,
          type_cuti: false
        },

        sheet: {
          add_holiday: false,
          detail_holiday: false
        },

        selected: {
          holiday: {},

          menu: 0
        },

        search: '',

        menu: [
          {
            text: 'Izin',
            value: 'ijin',
            icon: require('@/assets/icon/ijin.png')
          },
          {
            text: 'Cuti',
            value: 'cuti',
            icon: require('@/assets/icon/cuti.png')
          }
        ],

        list: [],
        pagination: {},
        limit: 10,

        picker: {
          submission: false,
          start: false,
          end: false
        },

        time: {
          start: false,
          end: false
        },

        upload: {
          folder: null,
          doc: null
        },

        form: {
          id: '',
          value: '',
          pengajuan_date: '',
          start_date: '',
          end_date: '',
          note: '',
          lampiran: '',

          check_time: false,
          start_time: '',
          end_time: '',
          
          type: ''
        },

        process: {
          load: false,
          form: false,
          file: false,
          doc: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/holiday',
          title: 'Izin & Cuti',
          description: 'Izin & Cuti Amanata',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      uploadfolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      uploaddocument: () => import(/* webpackPrefetch: true */ '@/components/upload/document.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.lampiran = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      },

      'upload.doc': {
        handler: function () {
          if (this.upload.doc) {
            this.process.doc = this.upload.doc.process

            if (!this.process.doc) {
              // CALLBACK FROM UPLOAD DOC

              this.form.lampiran = this.upload.doc.fileuri
            }
          }
        },
        deep: true
      },

      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      },

      // 'form.check_time': function(val) {
      //   if (val) {
      //     this.form.end_date = ''
      //     this.form.start_time = ''
      //     this.form.end_time = ''
      //   }
      // },

      'form.value': function(val) {
        if (val === '' || val < 1) {
          this.form.start_date = ''
          this.form.end_date = ''
        } else {
          if (this.form.start_date !== '') {
            this.form.end_date = moment(this.form.start_date, 'YYYY-MM-DD').add(parseInt(val) - 1, 'days').format('YYYY-MM-DD')
          }
        }
      },
      'form.start_date': function(val) {
        if (val !== '') {
          this.form.end_date = moment(val, 'YYYY-MM-DD').add(parseInt(this.form.value) - 1, 'days').format('YYYY-MM-DD')
        } else {
          this.form.end_date = ''
        }
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb_soft () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.05)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      to_slide (val) {
        this.selected.menu = val

        this.fetch()
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          limit: this.limit,
          search: this.search,

          type: this.menu[this.selected.menu].value
        }

        await this.$axios.$get(`${process.env.API_URL}user/cuti/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
            
            if (this.sheet.detail_holiday) {
              this.list.map(obj => {
                if (this.selected.holiday.id === obj.id) this.selected.holiday = obj
              })
            }
          }
        })
      },

      reset_first (type) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: '',
          value: '',
          pengajuan_date: '',
          start_date: '',
          end_date: '',
          note: '',
          lampiran: '',

          check_time: false,
          start_time: '',
          end_time: '',

          type: type
        }
        
        this.picker = {
          submission: false,
          start: false,
          end: false
        }

        this.time = {
          start: false,
          end: false
        }

        this.process.form = false

        this.message.error = ''
      },
      
      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          value: item !== undefined ? item.value : '',
          pengajuan_date: item !== undefined ? item.pengajuan_date : '',
          start_date: item !== undefined ? item.start_date : '',
          end_date: item !== undefined ? item.end_date : '',
          note: item !== undefined ? item.note : '',
          lampiran: item !== undefined ? item.lampiran : '',

          check_time: item !== undefined && item.start_time !== '' ? true : false,
          start_time: item !== undefined ? item.start_time : '',
          end_time: item !== undefined ? item.end_time : '',

          type: item !== undefined ? item.type : ''
        }
        
        this.picker = {
          submission: false,
          start: false,
          end: false
        }

        this.time = {
          start: false,
          end: false
        }

        this.process.form = false

        this.message.error = ''
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'user/cuti/submission' : 'admin/cuti/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API_URL}${url}`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {

              this.sheet.add_holiday = false
              
              this.fetch()

            } else {
              this.dialog.error = true
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>