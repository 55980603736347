<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card 
            flat
            color="transparent">
            <v-card-text
              class="pb-1">
              <v-menu
                ref="range"
                min-width="290px"
                max-width="290px"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                :close-on-content-click="false"
                v-model="picker.range">
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-text-field
                    readonly
                    
                    class="no_box"
                    solo

                    :color="set_color"
                    placeholder="Pilih Periode"

                    prepend-inner-icon="mdi-calendar-multiselect-outline"

                    hide-details

                    v-on="on"
                    v-bind="attrs"

                    v-model="date_range">
                  </v-text-field> -->

                  <v-card
                    v-on="on"
                    v-bind="attrs"

                    flat
                    color="#f2f2f2"
                    style="border-radius: 50px;">
                    <div
                      class="d-flex align-center pa-3">
                      <v-icon>mdi-calendar-multiselect-outline</v-icon>

                      <div
                        class="body-1 ml-1">
                        {{ filter.dates[0] | date_month }} - {{ filter.dates[1] | fulldate }}
                      </div>
                    </div>
                  </v-card>
                </template>
                
                <v-date-picker 
                  range
                  :color="set_color"
                  v-model="filter.dates"
                  @input="picker.range = filter.dates.length > 1 ? false : true">
                </v-date-picker>
              </v-menu>
            </v-card-text>

            <v-card-text
              class="pt-2">
              <v-list 
                flat 
                three-line 
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 3 : list"
                    :key="index"
                    :loading="process.load"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.length > 0"
                          @click="detail = item; sheet.detail = true;"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 20px;
                                background: #FFFFFF40;
                                border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;"
                          :style="`border-left: 4px solid ${set_color};`">
                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="caption mb-1 d-flex align-center">
                              <v-icon x-small>mdi-calendar-month-outline</v-icon>
                              
                              <span
                                class="ml-1">
                                {{ item.start_date | date_month }} - {{ item.end_date | fulldate }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-title
                              class="body-1 font-weight-bold mb-2">
                              {{ item.thp | price }}
                            </v-list-item-title>

                            <v-list-item-subtitle 
                              class="body-2">
                              <span
                                class="line-text-first one-line">
                                {{ item.account_name }} - {{ item.account_number }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <Empty v-if="!process.load && list.length < 1"
                margin="my-16 py-16"
                size="125"
                message="Dokumen Perusahaan" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.detail"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat>
            <v-card-title 
              @click="sheet.detail = false"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon 
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                {{ detail.user_name }}
              </span>
            </v-card-title>

            <v-card-text 
              class="pt-16 mt-3 px-4">
              <v-row
                align="center">
                <v-col
                  cols="5"
                  class="py-0">
                  <div
                    class="mb-2">
                    Payroll Cut-Off
                  </div>

                  <div
                    class="mb-2">
                    NIP
                  </div>

                  <div
                    class="mb-2">
                    Nama Karyawan
                  </div>

                  <div
                    class="mb-2">
                    Unit / Divisi
                  </div>

                  <div>
                    Jabatan
                  </div>
                </v-col>

                <v-col
                  cols="7"
                  class="py-0">
                  <div
                    class="mb-2">
                    : {{ detail.start_date | date_month_short }} - {{ detail.end_date | fulldate_short }}
                  </div>

                  <div
                    class="mb-2">
                    : {{ detail.user_code }}
                  </div>

                  <div
                    class="mb-2">
                    : {{ detail.user_name }}
                  </div>

                  <div
                    class="mb-2">
                    : {{ detail.unit_name }}
                  </div>

                  <div>
                    : {{ detail.user_position }}
                  </div>
                </v-col>
              </v-row>

              <v-divider 
                class="my-4"
                style="border-top: 2px dashed #e0e0e0;"/>

              <v-row
                align="center">
                <v-col
                  cols="5"
                  class="py-0">
                  <div
                    class="mb-2">
                    Nomor Rekening
                  </div>

                  <div>
                    Atas Nama Rekening
                  </div>
                </v-col>

                <v-col
                  cols="7"
                  class="py-0">
                  <div
                    class="mb-2">
                    : {{ detail.account_number !== '' ? detail.account_number : '-' }}
                  </div>

                  <div>
                    : {{ detail.account_name !== '' ? detail.account_name : '-' }}
                  </div>
                </v-col>
              </v-row>

              <v-divider 
                class="my-4"
                style="border-top: 2px dashed #e0e0e0;"/>

              <div
                v-for="(item, index) in detail.sallary_json_array"
                :key="index"
                :class="index > 0 ? 'mt-6' : ''">
                <div
                  class="body-1 black--text text-capitalize d-flex align-center mb-2"
                  style="font-weight: 500;">
                  <v-icon small color="black">mdi-transfer-right</v-icon>
                  
                  <span
                    class="ml-1">
                    {{ item.type }}
                  </span>
                </div>

                <div
                  v-for="(item_sub, index_sub) in item.child"
                  :key="index_sub">
                  <v-row
                    align="center">
                    <v-col
                      cols="7"
                      class="pb-0">
                      <div
                        class="d-flex align-start">
                        <v-icon>mdi-circle-small</v-icon>

                        <span
                          class="body-2">
                          {{ item_sub.label }}
                        </span>
                      </div>
                    </v-col>

                    <v-col
                      cols="5"
                      class="text-right pb-0">
                      {{ item_sub.nominal | price }}
                    </v-col>
                  </v-row>
                </div>

                <v-card
                  flat
                  color="#f0f0f0"
                  class="mt-5"
                  style="border-radius: 10px;">
                  <v-card-text
                    class="py-0">
                    <v-row
                      align="center">
                      <v-col
                        cols="7">
                        <div
                          class="body-1 black--text text-capitalize"
                          style="font-weight: 500;">
                          Total {{ item.type }}
                        </div>
                      </v-col>

                      <v-col
                        cols="5">
                        <div
                          class="body-1 black--text font-weight-bold text-right">
                          {{ item.child.reduce((a, item) => a + Number(item.nominal), 0) | price }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>

              <v-card v-if="Object.keys(detail).length > 0"
                flat
                color="#f0f0f0"
                class="mt-6"
                style="border-radius: 10px;">
                <v-card-text
                  class="py-0">
                  <v-row
                    align="center">
                    <v-col
                      cols="7">
                      <div
                        class="body-1 black--text text-capitalize"
                        style="font-weight: 500;">
                        Total Take Home Pay
                      </div>
                    </v-col>

                    <v-col
                      cols="5">
                      <div
                        class="body-1 black--text font-weight-bold text-right">
                        {{ total_sallary() | price }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from "moment"
  import "moment/locale/id"
  export default {
    middleware: 'authenticated',
    data () {
      return {
        sheet: {
          detail: false
        },

        filter: {
          dates: [
            moment().startOf('month').format('YYYY-MM-DD'),
            moment().endOf('month').format('YYYY-MM-DD')
          ]
        },

        detail: {},

        list: [],
        pagination: {},

        picker: {
          range: false,

          start_date: false,
          end_date: false
        },
        
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },

        // SEO
        content: {
          url: '/sallary',
          title: 'Gaji',
          description: 'Gaji Amanata',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'picker.range': function(val) {
        if (!val) this.fetch()
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      user() {
        return this.$store.state.member.auth.user
      },
      set_color () {
        return this.$store.state.header.config.color
      },

      date_range () {
        return this.filter.dates.join(' s/d ')
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          start_date: this.filter.dates.length > 0 ? this.filter.dates[0] : '',
          end_date: this.filter.dates.length > 0 ? this.filter.dates[1] : ''
        }

        await this.$axios.$get(`${process.env.API_URL}user/sallary/data`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {  
            this.list = response.results.data
            this.pagination = response.results.pagination
          } 
        })
      },

      total_sallary () {
        let total_income = 0, total_deduction = 0

        this.detail.sallary_json_array.forEach(obj => {
          if (obj.type === 'income') {
            total_income += obj.child.reduce((a, item) => a + Number(item.nominal), 0)
          } 
          
          if (obj.type === 'deduction') {
            total_deduction += obj.child.reduce((a, item) => a + Number(item.nominal), 0)
          }
        })

        return total_income - total_deduction
      },
    }
  }
</script>