<template>
  <v-card 
    flat 
    class="overflow-hidden"
    style="border-radius: 0px;">
    <v-app-bar 
      v-if="!loader && $route.path !== '/'"
      app
      absolute
      :color="set_color"
      elevation="0"
      :hide-on-scroll="false"
      :prominent="false"
      scroll-target="#scrolling-techniques-4"
      style="z-index: 9; border-radius: 0px 0px 30px 30px;">
      <div 
        v-if="$route.path === '/404' || $route.path === '/500'">
        <div 
          class="text-capitalize font-weight-bold white--text">
          Error
        </div>

        <div 
          class="white--text"
          style="font-size: 10px; opacity: 60%;">
          Amanata
        </div>
      </div>
      
      <div v-else
        class="d-flex align-center">
        <v-list
          color="transparent">
          <v-list-item
            class="pa-0">
            <v-list-item-avatar
              size="36"
              class="mr-3">
              <v-img 
                width="100%"
                height="100%"
                alt="amanata" 
                :src="user.image_url === '' ? require('@/assets/image/member_default.png') : user.image_url"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      color="#FFFFFF">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-list-item-avatar>
            
            <v-list-item-content>
              <v-list-item-subtitle
                class="caption white--text text-capitalize mb-1">
                Selamat {{ isDay }},
              </v-list-item-subtitle>

              <v-list-item-title 
                class="body-2 font-weight-bold white--text">
                {{ user.name }}
              </v-list-item-title>

            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <v-spacer />

      <div>
        <v-img 
          @click="sheet.list = true; fetch();"
          contain
          width="22"
          height="22"
          alt="amanata" 
          :src="require('@/assets/icon/bell.png')"
          class="ma-auto cursor-pointer">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular 
                size="20"
                indeterminate 
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>

      <div v-if="Object.keys(company).length > 0 && company.color.length > 0">
        <v-menu 
          transition="slide-x-transition"
          bottom
          right
          max-width="124">
          <template v-slot:activator="{ on, attrs }">
            <v-img 
              v-bind="attrs"
              v-on="on"
              contain
              width="22"
              height="22"
              alt="amanata" 
              :src="require('@/assets/icon/palette.png')"
              class="ma-auto cursor-pointer ml-3">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    size="20"
                    indeterminate 
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>
          
          <v-card
            flat
            class="pa-0"
            style="border-radius: 10px;">
            <v-btn
              v-for="(item, index) in company.color"
              :key="index"
              @click="item.active = true; save(index);"
              min-width="30"
              width="30"
              height="30"
              :color="item.hex"
              elevation="0"
              class="pa-0"
              style="border-radius: 0px;">
              <v-icon v-if="item.active"
                small 
                color="#FFFFFF">
                mdi-check-circle-outline
              </v-icon>
            </v-btn>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>

    <v-sheet 
      id="scrolling-techniques-4"
      class="overflow-y-auto"
      max-height="100vh"
      @scroll="onScroll">
      <div
        style="min-height: 100vh;">
        <Loader v-if="loader" />
        
        <div
          :class="$route.path === '/' ? 'background' : ''">
          <nuxt class="blur" />
        </div>
      </div>
    </v-sheet>

    <v-bottom-sheet
      v-model="sheet.list"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        style="border-radius: 0px;">
        <v-card-title 
          @click="sheet.list = false"
          class="cursor-pointer pa-4"
          style="position: absolute;
                width: 100%;
                z-index: 1;"
          :style="`background: ${set_color};`">
          <v-icon color="white">
            mdi-arrow-left-thick
          </v-icon>

          <span
            class="body-1 font-weight-bold text-capitalize white--text ml-1">
            Pengumuman
          </span>
        </v-card-title>

        <!-- <v-card-text
          style="margin-top: 250px;">
          <v-img 
            width="200"
            height="auto"
            alt="amanata" 
            :src="require('@/assets/image/coming_soon.png')"
            class="ma-auto">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  indeterminate 
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div
            class="body-1 font-weight-light line-text-second text-center mt-8 mb-6">
            Tunggu fitur-fitur menarik dari Amanata, untuk kemudahan kebutuhan Anda.
          </div>

          <div
            class="body-1 font-weight-bold black--text line-text-second text-center">
            - Amanata - 
          </div>
        </v-card-text> -->

        <v-card-text 
          class="pt-16 my-4 px-4">
          <v-text-field
            v-model="search"
            @click:clear="search = ''; fetch();"
            v-on:keyup.enter="fetch()"
            class="no_box mb-5"
            solo
            :color="set_color"
            single-line
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>

          <v-list 
            flat 
            three-line 
            color="transparent">
            <v-list-item-group>
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 3 : list"
                :key="index"
                :loading="process.load"
                type="article">
                <template>
                  <div>
                    <v-list-item v-if="!process.load && list.length > 0"
                      @click="toRead(item)"
                      :class="index < list.length - 1 ? 'mb-4' : ''"
                      class="pl-3"
                      style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                            border-radius: 20px;
                            background: #FFFFFF40;
                            border-bottom-left-radius: 0px;
                            border-top-left-radius: 0px;"
                      :style="!item.status ? `border-left: 4px solid ${set_color};` : 'border-left: 4px solid #e0e0e0;'">
                      <v-list-item-content>
                        <v-list-item-subtitle
                          class="caption mb-1 d-flex align-center">
                          <v-icon x-small>mdi-calendar-month-outline</v-icon>
                          
                          <span
                            class="ml-1">
                            {{ item.input_datetime | date }}
                          </span>
                        </v-list-item-subtitle>

                        <v-list-item-title
                          class="body-1 font-weight-bold mb-2">
                          {{ item.title }}
                        </v-list-item-title>

                        <v-list-item-subtitle 
                          class="body-2 line-text-first two-line">
                          {{ item.desc }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-list-item-group>
          </v-list>

          <Empty v-if="!process.load && pagination.total_page < 1"
            class="py-16"
            margin="my-16"
            size="125"
            message="Pengumuman" />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        style="border-radius: 0px;">
        <v-card-title 
          @click="sheet.detail = false"
          class="cursor-pointer pa-4"
          style="position: absolute;
                width: 100%;
                z-index: 1;"
          :style="`background: ${set_color};`">
          <v-icon color="white">
            mdi-arrow-left-thick
          </v-icon>

          <span
            class="body-1 font-weight-bold text-capitalize white--text ml-1">
            Detail Pengumuman
          </span>
        </v-card-title>

        <v-card-text 
          class="pt-16 my-4 px-4">
          <div
            class="body-2 mb-1 d-flex align-center">
            <v-icon small>mdi-calendar-month-outline</v-icon>
            
            <span
              class="ml-1">
              {{ detail.input_datetime | date }}
            </span>
          </div>

          <div
            class="body-1 font-weight-bold line-text-second  black--text mb-2">
            {{ detail.title }}
          </div>

          <div
             class="body-2 line-text-second">
            {{ detail.desc }}
          </div>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import Loader from "~/components/Loader.vue"
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        loader: true,
        sheet: {
          list: false,
          detail: false
        },
        scroll: true,
        search: '',
        detail: {},
        list: [],
        pagination: {},
        process: {
          load: false
        }
      }
    },
    components: {
      Loader,
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      token () {
        return this.$store.state.member.auth.token
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      company () {
        return this.$store.state.header.config.company
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) { 
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        } 
      }

      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }

      document.addEventListener('scroll', debounce(storeScroll), { passive: true })

      storeScroll()

      this.loader = false

      this.fetchCompany()
    },
    beforeCreate() {
      this.loader = true
    },
    created() {
      
    },
    methods: {
      onScroll () {
        const el = document.getElementById('scrolling-techniques-4')
        
        this.scroll = el.scrollTop < window.innerHeight ? true : false

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      },
      async fetch () {
        this.process.load = true

        let params = {
          limit: 1000,
          search: this.search
        }

        await this.$axios.$get(`${process.env.API_URL}user/announcement/data`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },

      async toRead (item) {
        await this.$axios.$post(`${process.env.API_URL}user/announcement/read`, {
          id: item.id
        })
        .then((response) => {
          if (response.status === 200) {
            this.detail = item

            this.sheet.detail = true

            this.fetch()
          }
        })
      },

      async fetchCompany () {
        let params = {
          company_id: Object.keys(this.user).length > 0 ? this.user.company_id : ''
        }

        await this.$axios.$get(`${process.env.API_URL}config`, { params })
        .then((response) => {
          if (response.status === 200) {
            response.results.data[0].color = JSON.parse(response.results.data[0].color)
            
            this.$store.commit('header/config/setCompany', response.results.data[0])
            Cookie.set('company', response.results.data[0])

            if (response.results.data[0].color.length > 0) {
              response.results.data[0].color.map((obj) => {
                if (obj.active) {
                  this.$store.commit('header/config/setColor', obj.hex)
  
                  Cookie.set('color', obj.hex)
                }
              })
            } else {
              this.$store.commit('header/config/setColor', '#7550e1')
  
              Cookie.set('color', '#7550e1')
            }

            this.styles()
          }
        })
      },

      async save (idx) {
        let forms = this.$store.state.header.config.company

        forms.color.map((obj, index) => {
          if (index === idx) {
            obj.active = true
          } else {
            obj.active = false
          }
        })

        forms = Object.assign({}, forms)
        forms.color = JSON.stringify(forms.color)

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API_URL}admin/config/update`, forms)
        .then((response) => {

          if (response.status === 200) {
            this.fetchCompany()
          }

        })
      },
      
      styles () {
        let style = document.createElement('style')
          
        // RESET STYLESHEET TO AUTO
        style.innerHTML = `
          ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: ${this.set_color};
          }

          html:not([data-scroll="0"]) .toolbar {
            background-color: ${this.set_color} !important;
          }
        `

        // ADD TO APPENDCHILD
        document.head.appendChild(style)
      }
    }
  }
</script>