<template>
  <div>
    <div @click="launchFile()">
      <slot name="activator"></slot>
    </div>

    <input 
      type="file"
      accept="application/pdf"
      ref="file"
      :name="filename"
      v-on:change="changeFile($event.target.files)"
      style="display:none">

    <v-dialog 
      v-model="dialog"
      width="500">
      <v-card
        class="pa-0 ma-0"
        flat>
        <v-card
          color="red accent-2">
          <v-list-item>
            <v-list-item-avatar color="red accent-2" size="30">
              <v-icon color="white" large>mdi-alert</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-card-text class="caption white--text pa-0">
                {{ message.error }}
              </v-card-text>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'upload-document',
    data: () => ({
      dialog: false,
      filename: 'file',
      message: {
        success: '',
        error: ''
      },
      process: {
        form: false
      }
    }),
    methods: {
      launchFile () {
        this.$refs.file.click()
      },
      changeFile (file) {
        let document = file[0]
        if (file.length > 0) {
          if (document.type != 'application/pdf') {
            this.dialog = true
            this.message.error = 'Silahkan pilih dokumen dengan format .pdf'
          } else if (document.size > 5242880) {
            this.dialog = true
            this.message.error = 'Maksimal dokumen 5MB'
          } else {
            let formData = new FormData()
                formData.append('key', 'file')
                formData.append('file', document)

            this.process.form = true
            this.$emit('input', {
              process: this.process.form
            })

            fetch(`${process.env.API_CDN}file.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              this.process.form = false

              this.$emit('input', {
                filename: response.data.filename,
                filepath: response.data.filepath,
                fileuri: response.data.fileuri,
                process : this.process.form
              })
            })
          }
        }
      }
    }
  }
</script>